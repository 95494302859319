<template>
  <el-container class="pad">
    <el-header class="pad-header">
      <PadHeader
        :pad-name="padName"
        :permission="permission"
        :pad-id="padID"
        :pad-owner="owner"
        :self-user-id="selfUserID"
        :deleted="deleted" />
    </el-header>
    <el-main class="pad-main" v-loading="loading">
      <PadEditor v-if="hasPermission && !noResult" :iframe-link="iframeLink" />
      <el-empty v-if="!hasPermission && !noResult"
                :image="NoPermissionImg"
                :image-size="300"
                description="无文档权限" />
      <el-empty v-if="noResult"
                :image="NoContentImg"
                :image-size="300"
                :description="errorDescription" />
    </el-main>
  </el-container>
</template>

<script>
import PadHeader from '@/views/Pad/PadHeader'
import PadEditor from '@/views/Pad/PadEditor'
import api from '@/api'
import { APICode } from '@/api/common'
import { dealOtherError } from '@/api/error'
import NoPermissionImg from '@/assets/no_permission.png'
import NoContentImg from '@/assets/no_content.png'

export default {
  components: {
    PadHeader,
    PadEditor
  },
  data () {
    return {
      intervalID: 0,
      loading: true,
      padID: this.$route.params.token.toString(),
      owner: '',
      selfUserID: '',
      padName: '加载中...',
      iframeLink: '',
      permission: 'no_permission',
      hasPermission: true,
      noResult: false,
      deleted: false,
      errorDescription: '出错了！请检测网络或刷新重新打开',
      NoPermissionImg,
      NoContentImg
    }
  },
  mounted () {
    this.openPad()
  },
  beforeDestroy () {
    clearInterval(this.intervalID)
  },
  methods: {
    async openPad () {
      try {
        const { data } = await api.openPad(this.padID)
        this.owner = data.padMeta.owner
        this.padName = data.padMeta.padName
        this.iframeLink = data.iframeLink
        this.permission = data.permission;
        ({ data: { userInfo: { userID: this.selfUserID } } } = await api.getUserSelfInfo())
        this.hasPermission = true
        this.noResult = false
        this.startDetectPermission()
      } catch (error) {
        this.iframeLink = ''
        this.permission = 'no_permission'
        if (error.resp) {
          const resp = error.resp
          if (resp.code === APICode.NoPermission) {
            this.hasPermission = false
            this.padName = '无权限'
            this.startDetectPermission()
            return
          }
          if (resp.code === APICode.NotFound || resp.code === APICode.ParamFormatError) {
            this.noResult = true
            this.padName = '文档不存在'
            this.errorDescription = '文档不存在！请检查网址是否正确'
            return
          }
        }
        this.noResult = true
        this.padName = '出错了'
        dealOtherError(this, error)
      } finally {
        this.loading = false
      }
    },
    startDetectPermission () {
      clearInterval(this.intervalID)
      this.intervalID = setInterval(this.handlePermissionChange, 3000)
    },
    async handlePermissionChange () {
      try {
        const { data: { permission } } = await api.getPadPermission(this.padID)
        if (permission !== this.permission) {
          await this.openPad()
        }
      } catch (error) {
        if (error.resp) {
          const resp = error.resp
          if (resp.code === APICode.NotFound) {
            this.noResult = true
            this.deleted = true
            this.padName = '文档已被删除'
            this.errorDescription = '文档已被所有者删除'
            clearInterval(this.intervalID)
            return
          }
        }
        this.noResult = true
        this.padName = '出错了'
        dealOtherError(this, error)
        clearInterval(this.intervalID)
      }
    }
  }
}
</script>

<style scoped>
.pad-header {
  padding: 0;
}
.pad {
  height: 100vh;
}
.pad-main {
  display: flex;
  justify-content: center;
  background-color: rgb(247, 247, 247);
  padding: 0;
  overflow-y: hidden;
}
</style>
