<template>
  <div>
    <el-popover ref="popover" trigger="click">
      <el-button icon="el-icon-lock" @click="handleClick" slot="reference" type="primary">权限设置</el-button>
      <el-skeleton ref="skeleton" style="width: 450px" :loading="loading" animated>
        <template v-slot:template>
          <base-row>
            <el-skeleton-item variant="h1" />
          </base-row>
          <BaseSeparationRow />
          <base-row>
            <el-skeleton-item variant="rect" style="height: 40px" />
          </base-row>
          <BaseSeparationRow />
          <div v-for="index in 6" :key="index">
            <BaseSeparationRow />
            <BaseSkeletonUserPermission />
          </div>
        </template>
        <base-row>
          <span>是否公开文档 （{{ isPublic ? '已公开' : '未公开' }}）</span>
          <el-switch
            v-loading="switching"
            :disabled="switching"
            :value="isPublic"
            @input="handleSwitch" />
        </base-row>
        <BaseSeparationRow />
        <base-row>
          <el-input :value="link"></el-input>
          <BaseSeparationColumn />
          <el-button @click="handleCopy">复制链接</el-button>
        </base-row>
        <BaseSeparationRow />
        <div class="permission-list" style="max-height: 300px; overflow:hidden">
          <ul>
            <li v-for="(user, index) in users" :key="index">
              <transition name="el-zoom-in-top">
                <div v-if="user.permission !== 'no_permission'">
                  <BaseSeparationRow />
                  <BaseRowUserPermission
                    ref="userPermission"
                    v-bind="user"
                    v-model="user.permission" />
                </div>
              </transition>
            </li>
          </ul>
        </div>
      </el-skeleton>
    </el-popover>
  </div>
</template>

<script>
import BaseRowUserPermission from '@/components/BaseRowUserPermission'
import BaseSkeletonUserPermission from '@/components/BaseSkeletonUserPermission'
import BaseSeparationColumn from '@/components/BaseSeparationColumn'
import BaseSeparationRow from '@/components/BaseSeparationRow'
import { bind, unbind } from 'focus-outside'
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  components: {
    BaseRowUserPermission,
    BaseSkeletonUserPermission,
    BaseSeparationColumn,
    BaseSeparationRow
  },
  props: {
    padId: String,
    padOwner: String,
    selfUserId: String
  },
  mounted () {
    const closeFn = () => {
      for (let i = 0; i < this.$refs.userPermission.length; i++) {
        this.$refs.userPermission[i].closeSelectMenu()
      }
      this.$refs.popover.doClose()
    }
    bind(this.$refs.popover.$el, closeFn, 'user_permission')
    bind(this.$refs.skeleton.$el, closeFn, 'user_permission')
  },
  beforeDestroy () {
    unbind(this.$refs.popover.$el)
    unbind(this.$refs.skeleton.$el)
  },
  data () {
    return {
      users: [],
      isPublic: false,
      firstLoading: true,
      loading: true,
      switching: false,
      link: window.location.toString()
    }
  },
  methods: {
    handleSwitch (value) {
      console.log(value)
      this.switching = true
      setTimeout(async () => {
        try {
          if (value) {
            await api.publicPad(this.padId)
            this.$message.success('公开文档成功！')
          } else {
            await api.privatePad(this.padId)
            this.$message.success('私有文档成功！')
          }
          this.isPublic = value
        } catch (error) {
          dealOtherError(this, error)
        } finally {
          this.switching = false
        }
      }, 500)
    },
    async handleClick () {
      if (!this.firstLoading) {
        await this.handleAPI()
        return
      }
      this.firstLoading = false
      setTimeout(async () => {
        await this.handleAPI()
        setTimeout(() => {
          this.loading = false
        }, 300)
      }, 600)
    },
    async handleAPI () {
      try {
        ({ data: { isPublic: this.isPublic } } = await api.isPublicPad(this.padId))
        const { data: { userPermissions } } = await api.listUserPermissions(this.padId)
        const userPermissionMap = new Map()
        userPermissions.forEach(user => userPermissionMap.set(user.userID, user.permission))
        const operatorPermission = this.selfUserId === this.padOwner ? 'owner' : 'admin'
        const userInfos = await Promise.all(
          userPermissions.map(user => api.getUserBasicInfo(user.userID)))
        const users = userInfos.map(({ data: { userInfo } }) => {
          return {
            padId: this.padId,
            userId: userInfo.userID,
            avatar: userInfo.avatar_link,
            nickname: userInfo.nickname,
            username: userInfo.username,
            permission: userPermissionMap.get(userInfo.userID),
            isOwner: userInfo.userID === this.padOwner,
            operatorPermission
          }
        })
        this.users = users.sort(this.cmpUserFn)
      } catch (error) {
        dealOtherError(this, error)
      }
    },
    async handleCopy () {
      try {
        await this.$copyText(this.link)
        this.$message.success('复制链接成功！')
      } catch (error) {
        console.log('copy link error:', error)
        this.$message.success('复制链接失败！')
      }
    },
    cmpUserFn (userA, userB) {
      let [levelA, levelB] = [this.getOwnerLevel(userA), this.getOwnerLevel(userB)]
      if (levelA !== levelB) return levelB - levelA;
      [levelA, levelB] = [this.getPermissionLevel(userA), this.getPermissionLevel(userB)]
      if (levelA !== levelB) return levelB - levelA
      return userA.nickname > userB.nickname ? 1 : -1
    },
    getOwnerLevel (user) {
      return user.isOwner ? 1 : 0
    },
    getPermissionLevel (user) {
      switch (user.permission) {
        case 'admin': return 4
        case 'read_write': return 3
        case 'read_only': return 2
        case 'no_permission': return 1
      }
    }
  }
}
</script>

<style scoped>
li {
  list-style: none;
}
.permission-list::-webkit-scrollbar {
  border-width: 2px;
}
a:focus,
input:focus,
p:focus,
div:focus{
  outline:none;
}
</style>
