<template>
  <div id="editor-block">
    <iframe name="embed_readwrite" :src="src" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
import { getRealLink } from '@/api/etherpad'

export default {
  props: {
    iframeLink: String
  },
  data () {
    return {}
  },
  computed: {
    src: function () {
      if (this.iframeLink) {
        const link = getRealLink(this.iframeLink)
        console.log('realLink:', link)
        return link
      }
      return ''
    }
  }
}
</script>

<style scoped>
#editor-block {
  width: 100%;
}
</style>
