<template>
  <base-row>
    <base-row justify="start">
      <el-skeleton-item
        variant="circle"
        style="height: 40px; width: 40px"
      />
      <BaseSeparationColumn />
      <el-skeleton-item
        variant="text"
        style="width: 120px;" />
    </base-row>
    <base-row justify="end">
      <el-skeleton-item
        variant="button"
        style="width: 200px" />
    </base-row>
  </base-row>
</template>

<script>
import BaseSeparationColumn from '@/components/BaseSeparationColumn'

export default {
  components: {
    BaseSeparationColumn
  }
}
</script>

<style scoped>

</style>
