<template>
  <base-row>
    <base-row justify="start">
      <BaseRowUser :avatar="avatar" :nickname="nickname" :username="username" />
    </base-row>
    <base-row justify="end">
      <el-select
        ref="select"
        :disabled="!allowChangePermission"
        :value="this.permission"
        @input="handleInput"
      >
        <el-option
          ref="option"
          v-for="item in permissionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </base-row>
  </base-row>
</template>

<script>
import BaseRowUser from '@/components/BaseRowUser'
import { bind, unbind } from 'focus-outside'
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  components: {
    BaseRowUser
  },
  model: {
    prop: 'permission'
  },
  props: {
    padId: String,
    userId: String,
    avatar: String,
    nickname: String,
    username: String,
    isOwner: Boolean,
    permission: String,
    operatorPermission: String
  },
  mounted () {
    bind(this.$refs.select.$refs.popper.$el, () => {}, 'user_permission')
    if (this.isOwner) {
      this.adminPermissionOptions = [
        {
          value: 'admin',
          label: '所有者'
        }
      ]
    }
  },
  beforeDestroy () {
    unbind(this.$refs.select.$refs.popper.$el)
  },
  data () {
    return {
      normalPermissionOptions: [
        {
          value: 'read_only',
          label: '可阅读'
        },
        {
          value: 'read_write',
          label: '可编辑'
        }
      ],
      adminPermissionOptions: [
        {
          value: 'admin',
          label: '管理员'
        }
      ],
      noPermissionOptions: [
        {
          value: 'no_permission',
          label: '取消分享'
        }
      ]
    }
  },
  methods: {
    async handleInput (permission) {
      console.log('permission change:', permission)
      if (permission === this.permission) {
        return
      }
      try {
        await api.sharePad(this.padId, this.userId, permission)
        this.$message.success('更改权限成功！')
        this.$emit('input', permission)
      } catch (error) {
        dealOtherError(this, error)
      }
    },
    closeSelectMenu () {
      this.$refs.select.handleClose()
    }
  },
  computed: {
    allowChangePermission () {
      if (this.isOwner) return false
      if (this.operatorPermission === 'owner') return true
      return this.permission !== 'admin'
    },
    permissionOptions () {
      const options = this.operatorPermission === 'owner' || !this.allowChangePermission
        ? this.normalPermissionOptions.concat(this.adminPermissionOptions)
        : this.normalPermissionOptions
      return options.concat(this.noPermissionOptions)
    }
  }
}
</script>

<style scoped>

</style>
