<template>
  <div>
    <el-popover v-model="visible" ref="popover">
      <el-button icon="el-icon-share" slot="reference" type="primary">多人协作</el-button>
      <el-autocomplete
        ref="autocomplete"
        placeholder="搜索用户昵称邀请协作"
        prefix-icon="el-icon-search"
        v-model="nickname"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        @select="handleSelect"
        style="width: 300px">
        <template v-slot="{ item }">
          <base-row class="user-row" justify="start">
            <BaseRowUser :avatar="item.avatar" :nickname="item.value" :username="item.username" />
          </base-row>
        </template>
      </el-autocomplete>
    </el-popover>
    <el-dialog
      :append-to-body="true"
      title="设置用户权限"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="400px"
    >
      <base-row>
        <base-row justify="start">
          <BaseRowUser :avatar="avatar" :nickname="nickname" :username="username" />
        </base-row>
        <base-row justify="end">
          <el-select v-model="permission">
            <el-option
              v-for="item in permissionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </base-row>
      </base-row>
      <span slot="footer">
        <el-button @click="handleClose" v-if="!loading">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleClick">
          {{ this.confirmLabel }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseRowUser from '@/components/BaseRowUser'
import { bind, unbind } from 'focus-outside'
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  components: {
    BaseRowUser
  },
  props: {
    padId: String,
    padOwner: String,
    selfUserId: String
  },
  mounted () {
    const closeFn = () => {
      setTimeout(() => {
        this.$refs.autocomplete.handleClear()
        this.$refs.popover.doClose()
      }, 100)
    }
    bind(this.$refs.popover.$el, closeFn)
    bind(this.$refs.autocomplete.$el, closeFn)
  },
  beforeDestroy () {
    unbind(this.$refs.popover.$el)
    unbind(this.$refs.autocomplete.$el)
  },
  data () {
    return {
      nickname: '',
      username: '',
      userID: '',
      visible: false,
      dialogVisible: false,
      avatar: '',
      permission: 'read_only',
      normalPermissionOptions: [
        {
          value: 'read_only',
          label: '可阅读'
        },
        {
          value: 'read_write',
          label: '可编辑'
        }
      ],
      adminPermissionOptions: [
        {
          value: 'admin',
          label: '管理员'
        }
      ],
      loading: false,
      task: 0
    }
  },
  computed: {
    confirmLabel () {
      return this.loading ? '分享中' : '确定'
    },
    permissionOptions () {
      return this.selfUserId === this.padOwner
        ? this.normalPermissionOptions.concat(this.adminPermissionOptions)
        : this.normalPermissionOptions
    }
  },
  methods: {
    async querySearch (queryString, callback) {
      try {
        const { data: { userInfos } } = await api.searchUsers(queryString)
        clearTimeout(this.task)
        const res = userInfos.map(user => {
          return {
            value: user.nickname,
            avatar: user.avatar_link,
            userID: user.userID,
            username: user.username
          }
        }).filter(user => user.userID !== this.padOwner)
        this.task = setTimeout(() => {
          callback(res)
        }, 1000)
      } catch (error) {
        dealOtherError(this, error)
        callback()
      }
    },
    handleSelect (item) {
      console.log('select user:', item)
      this.visible = false
      this.dialogVisible = true
      this.userID = item.userID
      this.username = item.username
    },
    handleClose () {
      this.dialogVisible = false
      this.$message.info('取消分享！')
    },
    async handleClick () {
      console.log('shareUserID:', this.userID)
      this.loading = true
      try {
        await api.sharePad(this.padId, this.userID, this.permission)
        setTimeout(() => {
          this.dialogVisible = false
          setTimeout(() => {
            this.loading = false
            this.$message.success('分享成功！可把文档链接分享给该用户')
          }, 300)
        }, 500)
      } catch (error) {
        this.dialogVisible = false
        setTimeout(() => {
          this.loading = false
          dealOtherError(this, error)
        }, 300)
      }
    }
  }
}
</script>

<style scoped>
.user-row {
  padding: 5px;
}
</style>
