<template>
  <div class="pad-header">
    <BaseHeader back-path="/home">
      <template v-slot:left>
        <span> {{ padName }} </span>
      </template>
      <template v-slot:right>
        <base-row justify="end" v-if="permission === 'admin' && !deleted">
          <BaseButtonShare
            :pad-id="padId"
            :pad-owner="padOwner"
            :self-user-id="selfUserId" />
          <BaseSeparationColumn />
          <BaseButtonPermission
            :pad-id="padId"
            :pad-owner="padOwner"
            :self-user-id="selfUserId" />
        </base-row>
        <el-divider direction="vertical" class="divider"></el-divider>
      </template>
    </BaseHeader>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import BaseButtonShare from '@/components/BaseButtonShare'
import BaseButtonPermission from '@/components/BaseButtonPermission'
import BaseSeparationColumn from '@/components/BaseSeparationColumn'

export default {
  components: {
    BaseHeader,
    BaseButtonShare,
    BaseButtonPermission,
    BaseSeparationColumn
  },
  props: {
    padId: String,
    padName: String,
    padOwner: String,
    permission: String,
    selfUserId: String,
    deleted: Boolean
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
.pad-header {
  height: 100%;
}
.divider {
  height: 2em;
}
</style>
