<template>
  <base-row justify="start">
    <base-row justify="start" style="width: 50px;">
      <el-avatar icon="el-icon-user-solid" :src="avatar"></el-avatar>
      <BaseSeparationColumn />
    </base-row>
    <base-row justify="start">
      <span> {{ nickname }} </span>
      <BaseSeparationColumn />
      <span> {{ '(' + username + ')' }} </span>
    </base-row>
  </base-row>
</template>

<script>
import BaseSeparationColumn from '@/components/BaseSeparationColumn'

export default {
  components: {
    BaseSeparationColumn
  },
  props: {
    avatar: String,
    nickname: String,
    username: String
  }
}
</script>

<style scoped>

</style>
